import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<{
    width?: number;
    height?: number;
  }>({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
};

export const renderMax = 1536;

export const useRenderSize = () => {
  const { height, width } = useWindowSize();

  const [size, setSize] = useState<{
    width?: number;
    height?: number;
  }>({
    width: undefined,
    height: undefined,
  });

  useMediaQuery({ query: `(max-width: ${renderMax}px)` });

  useEffect(() => {
    if (width) {
      setSize({
        width: renderMax > width ? width : renderMax,
        height,
      });
    }
  }, [width]);

  return size;
};
