import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import cn from 'classnames';
import { SvgIcon } from '@ui-elements';
import { usePortals, PortalProvider } from 'react-portal-hook';
import { GalleryModal } from './GalleryModal';
import { useWindowSize } from '~/common/hooks/use-windowsize';

export const Gallery = React.memo((props: any) => {
  return (
    <PortalProvider>
      <MainGallery
        gallerySlides={props.gallerySlides}
        contentId={props.contentId}
      />
    </PortalProvider>
  );
});

export const MainGallery = React.memo((props: any) => {
  const [galleryList, setGalleryList] = useState(
    props.gallerySlides.length < 10
      ? props.gallerySlides
      : props.gallerySlides.splice(0, 10)
  );
  const [galleryIndex, setGalleryIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(galleryList[galleryIndex]);
  const { width } = useWindowSize();

  const portalManager = usePortals();

  const handleClick = (index) => {
    if (index > galleryList.length - 1) {
      setGalleryIndex(0);
      setSelectedImage(galleryList[0]);
    } else if (index < 0) {
      setGalleryIndex(galleryList.length - 1);
      setSelectedImage(galleryList[galleryList.length - 1]);
    } else {
      setGalleryIndex(index);
      setSelectedImage(galleryList[index]);
    }
  };

  const handleShowModal = (event) => {
    event.stopPropagation();

    portalManager.open((portal) => (
      <GalleryModal
        mediaUrl={selectedImage.media}
        title={selectedImage.title}
        closeModal={portal.close}
      />
    ));
  };

  return (
    <div
      id={`cid-${props.contentId}`}
      className={cn('full-device-width', styles.gallery)}
    >
      <div className={styles.galleryWrapper}>
        <div className={styles.mainRow}>
          <div
            className={styles.galleryContainerLeft}
            onClick={() => handleClick(galleryIndex - 1)}
          >
            <div className={styles.galleryArrow}>
              <SvgIcon type="chevronLeft" size={2.2} color="#FFFFFF" />
            </div>
          </div>
          <img
            className={styles.mainImage}
            src={selectedImage.media}
            onClick={(e) => {
              if (width && width > 1025) {
                handleShowModal(e);
              } else {
                e.stopPropagation();
              }
            }}
          />
          <div className={styles.mainImageTitleContainer}>
            <span className={styles.mainImageTitle}>{selectedImage.title}</span>
          </div>
          <div
            className={styles.galleryContainerRight}
            onClick={() => handleClick(galleryIndex + 1)}
          >
            <div className={styles.galleryArrow}>
              <SvgIcon type="chevronRight" size={2.2} color="#FFFFFF" />
            </div>
          </div>
        </div>

        <div className={styles.thumbnailRow}>
          {galleryList.map((item, index) => {
            return (
              <img
                className={styles.thumbnailImage}
                key={index}
                src={item.media}
                alt=""
                onClick={() => handleClick(index)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
});
