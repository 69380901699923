import React from 'react';
import { Modal } from '../../common/components/ui-elements/modal';
import styles from './index.module.scss';

interface Props {
  closeModal: () => void;
  mediaUrl: string;
  title: string;
}

export const GalleryModal = React.memo<Props>(
  ({ closeModal, mediaUrl, title }) => {
    return (
      <Modal closeModal={closeModal} type={'media'} lockScroll>
        <div className={styles.galleryModalContainer}>
          <div className={styles.galleryModalContent}>
            <img src={mediaUrl} />
          </div>
          <div className={styles.galleryModalContentHeader}>
            <h2>{title}</h2>
          </div>
        </div>
      </Modal>
    );
  }
);
